
import { Route, Routes } from 'react-router-dom';
import Profile from './pages/Profile';
import CreateOrder from './pages/CreateOrder';
import Test from './pages/Test';
import ViewOrder from './pages/ViewOrder';
import SubmitQuote from './pages/SubmitQuote';
import Dashboard from './pages/Dashboard';
import PrepKit from './pages/PrepKit';

function App() {

  return (
    <>
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/profile" element={ <Profile/> } />
      <Route path="/createOrder" element={ <CreateOrder/> } />
      <Route path='/viewOrder' element={<ViewOrder/>} />
      <Route path='/submitQuote' element={<SubmitQuote/>} />
      <Route path="/test" element={ <Test/> } />
      <Route path="/prepkit" element={ <PrepKit/> } />
    </Routes>
    </>
    
  )
}

export default App;
