import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ComplexNavbar } from "../components/Navbar";
import { Button, Card, Checkbox, Input, Textarea, Tooltip, Typography, select } from "@material-tailwind/react";
import Loading from "../components/Loading";
import Login from "../components/Login";
import OrderItem from "../components/createOrder/OrderItem";
import { OrderItemData, Product, Supplier } from "adriftrfq-types";
import { redirect } from "react-router-dom";

function CreateOrder() {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [orderID, setOrderID] = useState<string>("");
    const [products, setProducts] = useState<Product[]>([]);
    const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
    const [orderItems, setOrderItems] = useState<string[]>(['']);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);

    const [note, setNote] = useState<string>("");

    const refsArray = useRef<{ requestData: () => OrderItemData }[]>([]);

    const handleItemSelected = (product: string, index: number) => {
        const newOrderItems = [...orderItems];
        newOrderItems[index] = product;
        setOrderItems(newOrderItems);
        if (index === orderItems.length - 1) {
            setOrderItems([...newOrderItems, '']);
        }
    };

    const [token, setToken] = useState<string | null>(null);
    const [response, setResponse] = useState<string>("");

    const baseurl: string = "https://api.adriftrfq.com";
    //const baseurl: string = "http://localhost:8000";

    useEffect(() => {
        if (token && token !== "") {
            const getSuppliers = async () => {
                try {
                  const response = await axios.get(`${baseurl}/suppliers/list`,
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  console.log(response.data);
                  setSuppliers(response.data.suppliers)
          
                } catch (error) {
                  console.log(error);
                }
              }
          
              getSuppliers();
        }
      }, [token]);

    useEffect(() => {
        const getToken = async () => {
            try {
                const t = await getAccessTokenSilently();
                setToken(t);
                fetchInventory(t, 1); // Fetch first page of inventory
            } catch (err) {
                console.log(err);
            }
        };
        getToken();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (token && token !== "" && page > 1) {
            fetchInventory(token, page);
        }
    }, [token, page]);

    const fetchInventory = async (token: string, page: number) => {
        try {
            const response = await axios.get(`${baseurl}/inventory/get`, {
                params: { page, limit: 500 },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (page === 1) {
                setProducts(response.data.data); // Set products directly for the first page
            } else {
                setProducts(prevProducts => [...prevProducts, ...response.data.data]);
            }

            setHasMore(page < response.data.pagination.totalPages);
            if (page < response.data.pagination.totalPages) {
                setPage(page + 1); // Fetch the next page
            } else {
                setLoadingProducts(false); // All products are loaded
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSupplier = (id: string, bool: boolean) => {
        let s = [...selectedSuppliers]
        if (bool) {
            s.push(id);
        } else {
            let index = s.findIndex(d => d === id);
            s.splice(index, 1);
        }
        setSelectedSuppliers(s);
    }

    const selectAllSuppliers = (selected: boolean) => {
        let s: string[] = [];
        if (selected) {
            suppliers.forEach(supplier => s.push(supplier.id))
        }
        console.log(suppliers);
        setSelectedSuppliers(s);
    }

    useEffect(() => {
        console.log(selectedSuppliers);
    }, [selectedSuppliers])

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('orderName', orderID);
        formData.append('suppliers', JSON.stringify(selectedSuppliers))
        
        if (note && note !== "") {
            formData.append('note', note);
        }
    
        refsArray.current.forEach((ref, index) => {
            if (ref) {
                const data = ref.requestData();
                if (data.sku !== '' || data.product !== '') {
                    formData.append(`items[${index}][sku]`, data.sku);
                    formData.append(`items[${index}][product]`, data.product);
                    formData.append(`items[${index}][quantity]`, data.quantity);

                    if (data.note && data.note !== "") {
                        formData.append(`items[${index}][note]`, data.note);
                    }
    
                    data.files.forEach((file, fileIndex) => {
                        formData.append(`items[${index}][files][${fileIndex}]`, file);
                    });
                }

                console.log(data);
            }
        });
    
        try {
            setLoadingProducts(true);
            const response = await axios.post(`${baseurl}/orders/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            
            console.log(response);
            console.log("Order ID: " + response.data.orderID)
            window.location.href = `https://app.adriftrfq.com/viewOrder?order=${response.data.orderID}`
        } catch (error) {
            console.log(error);
        }
    };
    

    if (isLoading || loadingProducts) {
        return <Loading />;
    }

    if (!user) {
        return <Login />;
    }

    const handleOrderID = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderID(e.target.value);
    };

    return (
        <>
        {
            isAuthenticated && (
              <>
                <div className='flex justify-center xl:pt-4'>
                    <ComplexNavbar profile={user.picture} />
                </div>
                <div className="mt-24 px-4 flex flex-col w-full items-center justify-center">
                    <div className="max-w-screen-xl w-full">
                        <div className="font-medium text-[24px]">Create Quote Request</div>
                        <div className="mt-2 w-64">
                            <Input
                                label="Request ID"
                                placeholder=""
                                value={orderID}
                                onChange={handleOrderID}
                            />
                        </div>
                        <div className="mt-4 font-medium text-[24px]">Items</div>
                        <div className="flex flex-col gap-4 mt-2">
                            {orderItems.map((item, index) => (
                                <OrderItem
                                    key={index}
                                    ref={(itemRef) => {
                                        if (itemRef) {
                                            // eslint-disable-next-line no-lone-blocks
                                            {/* @ts-ignore */}
                                            refsArray.current[index] = itemRef;
                                        }
                                    }}
                                    itemIndex={index}
                                    products={products}
                                    onItemSelected={(product) => handleItemSelected(product, index)}
                                />
                            ))}
                        </div>
                        <div className="mt-8 font-medium text-[24px]">Note</div>
                        <div className="text-blue-gray-700 font-sans font-normal text-sm mt-0.5">Write a note that you would like displayed with your quote request.</div>
                        <div className="mt-2">
                            <Textarea
                                label="Note"
                                className=""
                                onChange={(e) => setNote(e.target.value)}
                                value={note}
                            />
                        </div>
                        <div className="mt-4 font-medium text-[24px]">Suppliers</div>
                        <div className="text-blue-gray-700 font-sans font-normal text-sm mt-0.5">Select the suppliers that you would like to generate a submission link for.</div>
                        <div className="flex flex-col gap-4 mt-4">
                            <Card className="h-full w-full overflow-scroll">
                                    <table className="w-full min-w-max table-auto text-left">
                                        <thead>
                                            <tr>
                                                <th key={"selected"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 w-16">
                                                    <Tooltip
                                                        content="Select All"
                                                    >
                                                        <Checkbox onChange={(e) => {
                                                            selectAllSuppliers(e.target.checked)
                                                        }}/>
                                                    </Tooltip>
                                                </th>
                                                <th key={"name"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70"
                                                    >
                                                        Supplier Name
                                                    </Typography>
                                                </th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {suppliers.map((supplier, index) => {
                                            const isLast = index === suppliers.length - 1;
                                            const classes = isLast ? "p-4 py-2" : "p-4 py-2 border-b border-blue-gray-50";
                                            return (
                                                <tr key={index}>
                                                    <td className={classes}>   
                                                        <Checkbox
                                                            checked={selectedSuppliers.includes(supplier.id)}
                                                            onChange={(e) => {
                                                                handleSupplier(supplier.id, e.target.checked)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {supplier.name}
                                                        </Typography>
                                                    </td>
                                                                                                   
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </Card>
                        </div>

                        <div className="mt-8 flex w-full">
                            <Button onClick={handleSubmit}>Create Quote Request</Button>
                        </div>
                        <div className="mb-64"/>
                    </div>
                </div>
              </>
            )
        }
        </>
    );
}

export default CreateOrder;
