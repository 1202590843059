import { Button } from "@material-tailwind/react";
import axios from "axios";
import { useState } from "react";

function PrepKit() {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const downloadPdf = async () => {
    if (!file) {
      alert("Please upload a PDF file first.");
      return;
    }

    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.document.write(`
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Loading...</title>
            <style>
              body {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                font-family: Arial, sans-serif;
                background-color: #fff;
              }
              .spinner {
                border: 4px solid rgba(0, 0, 0, 0.1);
                width: 36px;
                height: 36px;
                border-radius: 50%;
                border-top-color: #0f172a;
                animation: spin 1s ease-in-out infinite;
              }
              @keyframes spin {
                to {
                  transform: rotate(360deg);
                }
              }
            </style>
          </head>
          <body>
            <div class="spinner"></div>
          </body>
        </html>
      `);
      newTab.document.close();
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://api.adriftrfq.com/upload-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const blobURL = window.URL.createObjectURL(blob);

      if (newTab) {
        newTab.location.href = blobURL;
      }

    } catch (error) {
      console.error('Error downloading the PDF', error);
      if (newTab) {
        newTab.close();
      }
    }
  };

  return (
    <div className="flex flex-col p-4 items-center justify-start">
      <h1 className="text-3xl font-bold mb-4">Prep Kit Processor</h1>
      <input id="pdfInput" className="hidden" type="file" onChange={handleFileChange} />

      {!file && (
        <div className="flex flex-col items-center justify-center">
          <p className="text-slate-900">Please select a PDF file.</p>
          <Button
            onClick={() => document.getElementById("pdfInput")?.click()}
            className="mt-4"
          >
            Upload PDF
          </Button>
        </div>
      )}

      {file && (
        <div className="flex flex-col items-center justify-center">
          <p className="text-slate-900">File selected: {file.name}</p>
          <Button
            onClick={downloadPdf}
            className="mt-4"
          >
            Process PDF
          </Button>
        </div>
      )}
    </div>
  );
}

export default PrepKit;
