import { redirect, useNavigate } from "react-router-dom";
import ViewOrder from "./ViewOrder";

function Test() {

    const navigate = useNavigate();

    const handleRedirect = () => {
        window.location.href ="https://app.adriftrfq.com/bob";
    }

    return (
        <div>
            <ViewOrder/>
        </div>
    )
}

export default Test;