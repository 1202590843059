import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { ComplexNavbar } from "../components/Navbar";
import { Button, Spinner } from "@material-tailwind/react";
import Loading from "../components/Loading";
import Login from "../components/Login";

function Profile() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    
  const [token, setToken] = useState<string | null>(null);
  const [response, setResponse] = useState<string>("");

  const baseurl: string = "https://api.adriftrfq.com"

  useEffect(() => {
      if (user && isAuthenticated) {
        const getToken = async () => {
          await getAccessTokenSilently().then(t => setToken(t)).catch(err => console.log(err))
      }
      getToken();
    } 
  }, [getAccessTokenSilently, isAuthenticated, user]);

  const handleClick = async () => {
    try {
      const response = await axios.get(`${baseurl}/test`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setResponse(response.data.message)
      
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Loading/>;
  }
  
  if (!user || !isAuthenticated) {
    return (<Login/>)
  }

  return (
    <>
        {
            isAuthenticated && (
              <>
                <div className='flex justify-center xl:pt-4'>
                  <ComplexNavbar profile={user.picture} />
                </div>
                <div className="mt-24 px-4 flex flex-col w-full items-center justify-center">
                    <div className="max-w-screen-xl">
                        <div className="font-medium text-[24px]">Profile</div>
                        
                        <div className="flex flex-col gap-4">
                          <img src={user.picture} alt={user.name} />
                          <h2>{user.name}</h2>
                          <p>{user.email}</p>

                          <div className="mt-8">
                            <div>Response: {response}</div>
                            <Button onClick={handleClick}>
                                API
                            </Button>
                          </div>
                        </div>
                    </div>
                </div>
              </>
              )
        }
    </>
  )
};

export default Profile;