import { Order, Quote } from "adriftrfq-types";
import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import { Button, Card, Option, Select, Typography } from "@material-tailwind/react";
import ViewFile from "../ViewFiles";
import OrderRecommendation from "./OrderRecommendation";

type ViewQuoteProps = {
    token: string | null;
    orderId: string;
    order: Order;
}

const ViewQuote: React.FC<ViewQuoteProps> = ({token, orderId, order}) => {
    const [quotes, setQuotes] = useState<Quote[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [selectedQuote, setSelectedQuote] = useState<Quote>();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalGST, setTotalGST] = useState<number>(0);

    const baseurl: string = process.env.REACT_APP_DEV === "true" ? "http://localhost:8000" : "https://api.adriftrfq.com";

    useEffect(() => {
        if (process.env.REACT_APP_DEV === "true") {
            const getQuotes = async () => {
                try {
                    const response = await axios.get(`${baseurl}/testData`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setQuotes(response.data);
                    console.log(response.data);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            }
    
            getQuotes();
        }
    }, [])    

    useEffect(() => {
        if (token && orderId && orderId !== "") {

            const getQuotes = async () => {
                try {
                    const response = await axios.get(`https://api.adriftrfq.com/quote/getByOrder`, {
                        params: {
                            orderId: orderId,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setQuotes(response.data);
                    console.log(response.data);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            }

            getQuotes();

        }
    }, [orderId, token])

    useEffect(() => {
        if (!selectedQuote || selectedQuote == null || selectedQuote === undefined)
            return;

        let price = 0;

        selectedQuote.items.forEach(item => {
            price+=Number(item.price.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", ""));
        })


        if (price !== 0) {
            let totalPrice = price;
            if (!selectedQuote.includesGST) {
                let calcGST = Number((price * 0.1).toFixed(2));
                totalPrice+=calcGST;
                setTotalGST(calcGST);
            } else {
                setTotalGST(Number((price - (price / 1.1)).toFixed(2)));
            }
            setTotalPrice(totalPrice);
        }
    }, [selectedQuote])

    if (loading) {
        return (
            <div>
                <Loading contentHeight={true}/>
            </div>
        );
    }
    
    return (
        <div>
            <div className="font-medium text-[24px] mt-16">Order Recommendation</div>
            <div className="text-blue-gray-700 font-sans font-normal text-sm mt-0.5">Below is the order recommendation, based on individual item price.</div>
            <OrderRecommendation order={order} quotes={quotes}/>
            <div className="font-medium text-[24px] mt-16">View Individual Quote</div>
            {selectedQuote && <div className="text-[14px] font-medium">Supplier ID: <span className="font-normal text-blue-gray-700">{selectedQuote?.supplierId}</span></div>}

            <div className="flex flex-row gap-4 w-full mt-4">
                <div className="max-w-96 w-full">
                    <Select
                        label="Select Quote"
                        value={selectedQuote?.supplierName}
                        onChange={(val) => {
                            setSelectedQuote(quotes.find(quote => (quote.supplierName === val)))
                        }}
                    >
                    {quotes.map(quote => {
                        return(
                            <Option value={quote.supplierName}>{quote.supplierName}</Option>
                        )
                    })}
                    </Select>
                </div>
                {selectedQuote && 
                    <div className="">
                        <Button variant="text" onClick={() => window.open(encodeURI(selectedQuote.quoteURL), "_blank")}>
                            View PDF Quote
                        </Button>
                    </div>
                }
            </div>
            <div className="mt-8">
                {selectedQuote && (
                    <div>
                        <Card className="h-full w-full overflow-scroll mt-8">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Product
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Quantity
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 w-48">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            View Files
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Product Name
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 w-48">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Price (incl. GST)
                                        </Typography>
                                    </th>
                                    
                                    {Object.keys(selectedQuote.items[0].data).map(col => {
                                        if (col === "Order Item" || col === "Uncertain Match") {
                                            return <></>
                                        }

                                        return (
                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70"
                                                >
                                                    {col}
                                                </Typography>
                                            </th>
                                        )
                                    })}
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {order.items.map((item, index) => {
                                    const isLast = index === order.items.length - 1;
                                    const classes = isLast ? "p-4 py-2" : "p-4 py-2 border-b border-blue-gray-50";

                                    // Find the corresponding extracted data item
                                    const extractedItem = selectedQuote.items.find((extractedItem: any) => extractedItem['orderItemSKU'] === item.sku);

                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.product}
                                                </Typography>
                                                
                                                {extractedItem?.note && (
                                                    <div className="mt-4">
                                                        <div className="relative max-w-72 min-w-[200px]">
                                                            <div className=" h-full min-h-[60px] bg-blue-gray-50 text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y border-0 resize-none transition-all placeholder-shown:border  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 !resize-none" placeholder=" ">
                                                                {extractedItem.note}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.quantity}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} w-48`}>
                                                <ViewFile urls={item.files} />
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal max-w-72"
                                                >
                                                    {extractedItem?.productName}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {extractedItem && Number((Number(extractedItem?.price.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", "")) * (selectedQuote.includesGST ? 1 : 1.1)).toFixed(2))}
                                                </Typography>
                                            </td>
                                            {/* Display data points for extracted item that was matched with item.product */}
                                            {extractedItem && Object.keys(selectedQuote.items[0].data).map((col, colIndex) => {
                                                if (col === "Order Item" || col === "Uncertain Match") {
                                                    return <></>
                                                }
                                                return (
                                                    <td key={colIndex} className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {/* @ts-ignore */}
                                                            {extractedItem.data[col]}
                                                        </Typography>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
                    <div className="text-black mt-8 flex flex-col">
                        <div className="text-2xl">Price: <span className="font-normal opacity-50">${totalPrice.toLocaleString()}</span> {selectedQuote.includesGST ? <span className="text-lg font-normal opacity-50">(incl. ${totalGST.toLocaleString()} GST)</span> : <span className="text-lg font-normal opacity-50">(incl. ${totalGST.toLocaleString()} GST)</span>}</div>
                        {/*<div className="flex flex-col">
                            <Checkbox label="Quote PDF Includes GST" disabled={true} checked={selectedQuote.includesGST}
                            />
                            <div className="opacity-50 text-sm ml-[2.85rem]">
                                Indicate whether the individual unit prices includes GST. If not, GST will be automatically calculated
                            </div>
                        </div>*/}
                    </div>
                    <div className="mt-8">
                        <div>
                            Further Notes
                        </div>
                        <div className="mt-4">
                            <div className="relative w-full min-w-[200px]">
                                <div className="peer w-full h-full min-h-[100px] bg-blue-gray-50 text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y border-0 resize-none transition-all placeholder-shown:border  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 !resize-none" placeholder=" ">
                                    {selectedQuote.note}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ViewQuote;