import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Input, Button, Dialog, DialogHeader, DialogBody, DialogFooter, IconButton, input, Textarea } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { OrderItemData, Product } from 'adriftrfq-types';
import { v4 as uuidv4, validate } from 'uuid';

type OrderItemProps = {
    products: Product[];
    onItemSelected: (product: string) => void;
    itemIndex: number;
}

const OrderItem = forwardRef((props: OrderItemProps, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [quantity, setQuantity] = useState('');
    const [sku, setSKU] = useState('');
    const [attachments, setAttachments] = useState<File[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [filteredSkus, setFilteredSkus] = useState<Product[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isTypingProduct, setIsTypingProduct] = useState(false);
    const [isTypingSKU, setIsTypingSKU] = useState(false);
    const [skuDisabled, setSkuDisabled] = useState(false);

    const [note, setNote] = useState<string>("");
    const [noteInputVisible, setNoteInputVisible] = useState<boolean>(false);

    const requestData = (): OrderItemData => {
        return { sku, product: inputValue, quantity, files: attachments, note: note };
    };

    useImperativeHandle(ref, () => ({
        requestData,
    }));

    useEffect(() => {
        console.log(isTypingProduct);
        if (inputValue && isTypingProduct) {
            
            const filtered = props.products.filter(product =>
                product.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredProducts(filtered);
            
            if (filtered.length === 0) {
                if (!validate(sku)) {
                    const newSku = uuidv4();
                    setSKU(newSku);
                    setSkuDisabled(true);
                }
            } else {
                if (!validate(sku))
                    setSkuDisabled(false);
                
            }
        } else {
            setFilteredProducts([]);
            if (inputValue === "" && validate(sku)) {
                setSKU("");
                setSkuDisabled(false);
            }
            
        }
    }, [inputValue, props.products, isTypingProduct]);

    useEffect(() => {
        if (sku && isTypingSKU) {
            const filtered = props.products.filter(product =>
                product.sku.toLowerCase().includes(sku.toLowerCase())
            );
            setFilteredSkus(filtered);
        } else {
            setFilteredSkus([]);
        }
    }, [sku, props.products, isTypingSKU]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        setIsTypingProduct(true);
        setIsTypingSKU(false);
        props.onItemSelected(value);
    };

    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuantity(value);
    };

    const handleSKUChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSKU(value);
        setIsTypingSKU(true);
        setIsTypingProduct(false);
        props.onItemSelected(value);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        setAttachments(prev => [...prev, ...files]);
    };

    const handleProductSelect = (product: Product) => {
        setInputValue(product.name);
        setSKU(product.sku);
        setFilteredProducts([]);
        setFilteredSkus([]);
        setIsTypingProduct(false);
        setIsTypingSKU(false);
        setSkuDisabled(false);
        props.onItemSelected(product.name);
    };

    const handleButtonClick = () => {
        if (attachments.length === 0) {
            document.getElementById(`fileInput${props.itemIndex}`)?.click();
        } else {
            setOpenDialog(true);
        }
    };

    const handleRemoveFile = (index: number) => {
        setAttachments(prev => prev.filter((_, i) => i !== index));
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className='flex flex-col'>
            <div className="relative flex flex-row grid-rows-1 w-full gap-2 lg:gap-4 xl:gap-8">
                <div className='col-start-1 w-64'>
                    <Input
                        label="SKU"
                        placeholder="Start typing..."
                        value={sku}
                        onChange={handleSKUChange}
                        onFocus={() => setIsTypingSKU(true)}
                        disabled={skuDisabled}
                        onBlur={() => {
                            const exactMatch = filteredSkus.find(product =>
                                product.sku.toLowerCase() === sku.toLowerCase()
                            );
                            if (exactMatch) {
                                handleProductSelect(exactMatch);
                            }
                        }}
                    />
                    {filteredSkus.length > 0 && (
                        <div className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                            {filteredSkus.map((product, index) => (
                                <div
                                    key={index}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleProductSelect(product)}
                                >
                                    {product.sku}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='col-start-2 w-64'>
                <Input
                    label="Product"
                    placeholder="Start typing..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => setIsTypingProduct(true)}
                    onBlur={() => {
                        const exactMatch = filteredProducts.find(product =>
                            product.name.toLowerCase() === inputValue.toLowerCase()
                        );
                        if (exactMatch) {
                            handleProductSelect(exactMatch);
                        }
                    }}
                />
                    {filteredProducts.length > 0 && (
                        <div className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                            {filteredProducts.map((product, index) => (
                                <div
                                    key={index}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleProductSelect(product)}
                                >
                                    {product.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='col-start-3'>
                    <Input
                        label="Quantity"
                        placeholder="Quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                    />
                </div>
                <div className='col-start-4'>
                    <Button onClick={handleButtonClick} className=''>
                        {attachments.length > 0 ? `${attachments.length} Attachments` : "Upload Attachments"}
                    </Button>
                    <input
                        id={`fileInput${props.itemIndex}`}
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="hidden"
                    />
                    <Dialog open={openDialog} handler={setOpenDialog}>
                        <DialogHeader>Attachments</DialogHeader>
                        <DialogBody>
                            {attachments.length > 0 ? (
                                <ul>
                                    {attachments.map((file, index) => (
                                        <li key={index} className="flex justify-between items-center">
                                            {file.name}
                                            <IconButton
                                                size="md"
                                                color="black"
                                                variant="text"
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                <XMarkIcon className="h-6 w-6" />
                                            </IconButton>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No attachments</p>
                            )}
                        </DialogBody>
                        <DialogFooter className='flex flex-row gap-2'>
                            <Button onClick={() => document.getElementById(`fileInput${props.itemIndex}`)?.click()} color="black">
                                Upload More
                            </Button>
                            <Button onClick={handleCloseDialog} color="black" variant='outlined'>
                                Close
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </div>
                <div className='col-start-5'>
                    <Button disabled={noteInputVisible} variant='text' onClick={() => setNoteInputVisible(true)}>
                        Add Note
                    </Button>
                </div>
            </div>
            <div className='w-full'>
            {noteInputVisible && (
                <div className="mt-4">
                    <Textarea
                    className='min-h-12'
                    value={note || ""}
                    onChange={(e) => setNote(e.target.value)}
                    onBlur={() => {
                        if (note === "")
                            setNoteInputVisible(false);
                    }}
                    label="Note"
                    />
                </div>
            )}
            </div>
        </div>
    );
});

export default OrderItem;
