import { Auth0Provider } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import App from "./App";
import { Button, ThemeProvider } from "@material-tailwind/react";
import { ComplexNavbar } from "./components/Navbar";

type Props = { children?: ReactNode };

export const Auth0ProviderWithNavigate: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const redirectUri = process.env.REACT_APP_DEV === 'true' ? "http://localhost:3000/callback" : "https://app.adriftrfq.com/callback";
  const audience = process.env.REACT_APP_DEV === 'true' ? "http://localhost:8000" : "https://api.adriftrfq.com";

  // @ts-ignore
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    console.error('Missing environment variables');
    return null;
  }

  return (
    <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: "https://app.adriftrfq.com/",
      audience: "https://api.adriftrfq.com",
      scope: 'openid profile email'
    }}
    onRedirectCallback={onRedirectCallback}
    
    >
      <App/>
    </Auth0Provider>
  );
};
