import { Card, Typography } from "@material-tailwind/react";
import { Order, Quote, QuoteItem } from "adriftrfq-types";
import { useEffect, useState } from "react";

type OrderRecommendationProps = {
    order: Order;
    quotes: Quote[];
}

type Item = {
    orderItem: string | undefined;
    quoteItem: string | undefined;
    supplierName: string | undefined;
    price: number | undefined;
}

const OrderRecommendation: React.FC<OrderRecommendationProps> = ({order, quotes}) => {
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        const tempItems: Item[] = []
        if (order && quotes) {
            order.items.forEach(orderItem => {

                const collatedQuoteItems: Map<Quote, QuoteItem> = new Map<Quote, QuoteItem>();

                quotes.forEach(quote => {
                    const quoteItem = quote.items.find(qi => (qi.orderItemSKU === orderItem.sku));
                    if (quoteItem)
                        collatedQuoteItems.set(quote, quoteItem);
                })

                const lowestPriceQuote = findLowestPriceQuote(collatedQuoteItems);
                if (!lowestPriceQuote) {
                    return;
                }
                
                tempItems.push({
                    orderItem: orderItem.product,
                    quoteItem: lowestPriceQuote?.[1].productName,
                    supplierName: lowestPriceQuote?.[0].supplierName,
                    price: Number((Number(lowestPriceQuote?.[1].price.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", "")) * (lowestPriceQuote[0].includesGST ? 1 : 1.1)).toFixed(2)),

                })
            })
            setItems(tempItems);
        }
    }, [order, quotes])

    function findLowestPriceQuote(map: Map<Quote, QuoteItem>): [Quote, QuoteItem] | null {
        let lowestPriceQuote: Quote | null = null;
        let lowestPriceItem: QuoteItem | null = null;
        let lowestPrice: number = Infinity;
    
        map.forEach((quoteItem, quote) => {
            let price = Number(quoteItem.price.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", ""));
            if (!quote.includesGST)
                price*=1.1;
            if (price < lowestPrice) {
                lowestPrice = price;
                lowestPriceQuote = quote;
                lowestPriceItem = quoteItem;
            }
        });
    
        return lowestPriceQuote && lowestPriceItem ? [lowestPriceQuote, lowestPriceItem] : null;
    }

    return (
        <>
            {quotes.length > 0 && 

            <div>
                <Card className="h-full w-full overflow-scroll mt-4">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Product
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 w-48">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Supplier
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Quote Product Name
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 w-48">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Quote Price (incl. GST)
                                        </Typography>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    const isLast = index === order.items.length - 1;
                                    const classes = isLast ? "p-4 py-2" : "p-4 py-2 border-b border-blue-gray-50";

                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.orderItem}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.supplierName}
                                                </Typography>
                                            </td>
                                            <td className={classes + " max-w-72"}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.quoteItem}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal min-w-64"
                                                >
                                                    {item.price}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
            </div>
            
            }
        </>
    )
}

export default OrderRecommendation;