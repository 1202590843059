import React, { useState, ChangeEvent, useEffect } from "react";
import axios from "axios";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import Loading from "../Loading";

type InventoryDialogProps = {
  token: string | null;
};

const InventoryDialog: React.FC<InventoryDialogProps> = ({ token }) => {
  const [open, setOpen] = useState(false);
  const [inventoryExists, setInventoryExists] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [uploading, setUploading] = useState<boolean>(false);

  const baseurl: string = "https://api.adriftrfq.com";

  const handleOpen = async () => {
    setOpen(!open);
    if (!open && token && token !== "") {
      try {
        const response = await axios.get(`${baseurl}/inventory/check`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInventoryExists(response.data.inventoryExists);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const handleConfirm = async () => {
    if (!file) return;

    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${baseurl}/inventory/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setUploading(false);
      console.log(response.data.message);
    } catch (error) {
      console.log(error);
    }

    handleOpen();
  };

  return (
    <>
      <Button variant="outlined" className="font-normal text-[16px] cursor-pointer px-4" onClick={handleOpen}>
        Upload Inventory
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Upload Inventory</DialogHeader>
        <DialogBody>
          {uploading && <Loading contentHeight={true}/>}
          <div className={`${uploading ? "hidden" : ""}`}>
            {inventoryExists ? (
              <div className="text-red-400">You already have an inventory, uploading will replace it.</div>
            ) : (
              <div>You do not have an inventory - upload one!</div>
            )}
            <input id="fileInput" type="file" accept=".csv" onChange={handleFileChange} className="hidden" />
            <Button onClick={() => document.getElementById('fileInput')?.click()} color="black" className="mt-2">
              {file === null ? "Upload" : file.name}
            </Button>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" onClick={handleConfirm} className="mr-1">
            <span>Save</span>
          </Button>
          <Button variant="outlined" onClick={handleOpen}>
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default InventoryDialog;
