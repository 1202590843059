import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ComplexNavbar } from "../components/Navbar";
import { DatabaseItemData, Order, Quote, QuoteItem } from "adriftrfq-types";
import { Button, Card, Checkbox, Input, Textarea, Tooltip, Typography } from "@material-tailwind/react";
import ViewFile from "../components/ViewFiles";
import Loading from "../components/Loading";
import SuccessDialog from "../components/quote/submit/SuccessDialog";

function SubmitQuote() {
    const [order, setOrder] = useState<Order>();

    const [notes, setNotes] = useState<{ [sku: string]: string }>({});
    const [noteInputVisible, setNoteInputVisible] = useState<{ [sku: string]: boolean }>({});

    const [furtherNotes, setFurtherNotes] = useState<string>("");

    const [orderId, setOrderID] = useState<string>();
    const [supplierId, setSupplierID] = useState<string>("SupplierID");
    const [supplierName, setSupplierName] = useState<string>("Supplier Name");

    const [file, setFile] = useState<File | null>(null);
    const [extractedData, setExtractedData] = useState<{ columns: string[], items: any[] }>({ columns: [""], items: [""]});
    const [pdfURL, setPdfUrl] = useState<string>("");

    const [price, setPrice] = useState<number>(0);
    const [includesGST, setIncludesGST] = useState<boolean>(false);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalGST, setTotalGST] = useState<number>(0);

    const [orderName, setOrderName] = useState<string>("");
    const [searchParams] = useSearchParams();

    const [isLoading, setLoading] = useState<boolean>(false);

    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        const order = searchParams.get('order');
        if (order) {
            setOrderID(order);
        }
        const supplier = searchParams.get('s');
        if (supplier) {
            setSupplierID(supplier);
        }
        const supplierName = searchParams.get('supplier');
        if (supplierName) {
            setSupplierName(supplierName);
        }
    }, [searchParams]);

    useEffect(() => {
        if (order) {
            if (order.name && order.name !== order.id)
                setOrderName(order.name);
        }
    }, [order]);

    const baseurl: string = process.env.REACT_APP_DEV === "true" ? "http://localhost:8000" : "https://api.adriftrfq.com";

    useEffect(() => {
        if (orderId) {
            const getOrder = async () => {
                try {
                    const response = await axios.get(`${baseurl}/orders/get`,
                        {
                            params: {
                                orderID: orderId
                            },
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    );

                    console.log(response);
                    setOrder(response.data.order);

                } catch (error) {
                    console.log(error);
                }
            }

            getOrder();
        }
    }, [orderId]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            setFile(file);
            console.log("Setting file")
        }
    };

    useEffect(() => {
        if (!file)
            return;

        // Send to backend
        const submitPDF = async () => {
            if (!order || !file)
                return;

            const formData = new FormData();
            formData.append('file', file);

            try {
                const orderItems: string[] = [];
                order.items.forEach(item => {
                    orderItems.push(item.product);
                })
                formData.append('orderItems', JSON.stringify(orderItems));

                setLoading(true);
                const response = await axios.post(`${baseurl}/quote/upload`, formData);

                setLoading(false);

                const jsonObject = response.data.readData; // The JSON object containings cols & data points
                setExtractedData(jsonObject);
                setPdfUrl(response.data.pdfURL)
                setFile(null);
                console.log(response);
            } catch (error) {
                console.log(error);
            }
        }

        submitPDF();
    }, [file]);

    const submitQuote = async () => {
        if (!order)
            return;
        
        try {
            // make JSON

            let items: QuoteItem[] = [];

            extractedData.items.forEach(item => {

                let orderItem = "";
                const obj = order.items.find(orderItem => orderItem.product === item["Order Item"]);
                if (obj) {
                    orderItem = obj.sku;
                }

                
                const newItem = {};
                extractedData.columns.forEach(col => {
                    if (col !== "Product Name" && col !== "Price" && col !== "Order Item") {
                        // @ts-ignore
                        newItem[col] = item[col];
                    }
                    
                })
                
                const quoteItem: QuoteItem = {orderItemSKU: orderItem, productName: item["Product Name"], price: item["Price"], note: notes[orderItem], data: newItem}
                items.push(quoteItem);
            })

            const quote: Quote = {orderId: order.id, supplierId: supplierId, supplierName: supplierName, quoteURL: pdfURL, items: items, note: furtherNotes, includesGST: includesGST }
            console.log(quote);
            const response = await axios.post(`${baseurl}/quote/submit`, quote);
            setSuccess(true);

            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!extractedData || extractedData.columns.length === 1)
            return;

        console.log(extractedData.columns);

        let price = 0;
        extractedData.items.forEach(item => {
            price+=Number((item["Price"] as string).replaceAll("$", "").replaceAll(",", ""));
        })
        setPrice(price);
    }, [extractedData])

    useEffect(() => {
        if (price !== 0) {
            let totalPrice = price;
            if (!includesGST) {
                let calcGST = Number((price * 0.1).toFixed(2));
                totalPrice+=calcGST;
                setTotalGST(calcGST);
            } else {
                setTotalGST(Number((price - (price / 1.1)).toFixed(2)));
            }
            setTotalPrice(totalPrice);
        }
    }, [price, includesGST])

    if (!order || isLoading) {
        return (
            <div>
                <Loading />
            </div>
        );
    }

    return (
        <div>
            <div className="mt-12 px-4 flex flex-col w-full items-center justify-center">
                <div className="max-w-screen-xl w-full">
                    <div className="flex flex-row">
                        <div className="flex flex-col">
                            <div className="font-medium text-[24px]">Submitting Quote</div>
                            <div className="text-[14px] font-medium">Quote Request: <span className="font-normal opacity-50">{order.name}</span></div>
                            <div className="text-[14px] font-medium">Quote Request ID: <span className="font-normal opacity-50">{orderId}</span></div>
                            <div className="text-[14px] font-medium">Supplier: <span className="font-normal opacity-50">{supplierName}</span></div>
                            <div className="text-[14px] font-medium">SupplerID: <span className="font-normal opacity-50">{supplierId}</span></div>
                        </div>
                        <input id="pdfInput" className="hidden" type="file" accept=".pdf" onChange={handleFileChange} />
                        <Button className="ml-auto h-12 align-bottom items-baseline" onClick={() => document.getElementById("pdfInput")?.click()}>
                            Upload PDF
                        </Button>
                        {/*<Button className="ml-4 h-12 align-bottom items-baseline" onClick={tempSubmitQuote}>
                            Get Data
                        </Button>*/}
                    </div>
                            
                    {(order.note && order.note !== "") && 
                        <>
                            <div className="mt-8 font-medium text-[24px]">Note</div>
                            <div className="text-blue-gray-700 font-sans font-normal text-sm mt-0.5">This note was attched to the quote request by the requestee.</div>
                            <div className="mt-2 peer w-full h-full min-h-[100px] bg-blue-gray-50 text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y border-0 resize-none transition-all placeholder-shown:border  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 !resize-none" placeholder=" ">
                                {order.note}
                            </div>
                        </>
                    }

                    <Card className="h-full w-full overflow-scroll mt-8">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Product
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            Quantity
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 w-48">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            View Files
                                        </Typography>
                                    </th>
                                    {extractedData.columns.map(col => {
                                        if (col === "Order Item") {
                                            return <></>
                                        }

                                        return (
                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70"
                                                >
                                                    {col}
                                                </Typography>
                                            </th>
                                        )
                                    })}
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {order.items.map((item, index) => {
                                    const isLast = index === order.items.length - 1;
                                    const classes = isLast ? "p-4 py-2" : "p-4 py-2 border-b border-blue-gray-50";

                                    // Find the corresponding extracted data item
                                    const extractedItem = extractedData.items.find((extractedItem: any) => extractedItem['Order Item'] === item.product);

                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.product}
                                                </Typography>
                                                {item?.note && (
                                                    <div className="mt-4">
                                                        <div className="relative min-w-[200px]">
                                                            <div className=" h-full min-h-[60px] bg-blue-gray-50 text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y border-0 resize-none transition-all placeholder-shown:border  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 !resize-none" placeholder=" ">
                                                                {item.note}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <Button className={`mt-2 ${noteInputVisible[item.sku] ? "hidden" : ""}`} size="sm" variant='text' onClick={() => setNoteInputVisible(prev => ({ ...prev, [item.sku]: !prev[item.sku] }))}>
                                                    Add Note
                                                </Button>
                                                {noteInputVisible[item.sku] && (
                                                    <div className="mt-4">
                                                        <Textarea
                                                        value={notes[item.sku] || ""}
                                                        onChange={(e) => setNotes({ ...notes, [item.sku]: e.target.value })}
                                                        onBlur={() => {
                                                            if (!notes[item.sku] || notes[item.sku] === "")
                                                                setNoteInputVisible(prev => ({ ...prev, [item.sku]: false }))
                                                        }}
                                                        label="Note"
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.quantity}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} w-48`}>
                                                <ViewFile urls={item.files} />
                                            </td>
                                            {/* Display data points for extracted item that was matched with item.product */}
                                            {extractedItem && extractedData.columns.map((col, colIndex) => {
                                                if (col === "Order Item") {
                                                    return <></>
                                                }
                                                return (
                                                    <td key={colIndex} className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {extractedItem[col]}
                                                        </Typography>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>

                    <div className="text-black mt-8 flex flex-col">
                        <div className="text-2xl">Price: <span className="font-normal opacity-50">${totalPrice.toLocaleString()}</span> {includesGST ? <span className="text-lg font-normal opacity-50">(incl. ${totalGST.toLocaleString()} GST)</span> : <span className="text-lg font-normal opacity-50">(incl. ${totalGST.toLocaleString()} GST)</span>}</div>
                        <div className="flex flex-col">
                            <Checkbox label="Quote PDF Includes GST" onChange={(e) => {
                                    setIncludesGST(e.target.checked);
                                }}
                            />
                            <div className="opacity-50 text-sm ml-[2.85rem]">
                                Indicate whether the individual unit prices includes GST. If not, GST will be automatically calculated
                            </div>
                        </div>
                    </div>
                    <div className="mt-8">
                        <div>
                            Further Notes
                        </div>
                        <div className="mt-4">
                            <Textarea
                                label="Quote Terms & Conditions/Further Notes"
                                onChange={(e) => setFurtherNotes(e.target.value)}
                                value={furtherNotes}
                            />
                        </div>
                    </div>
                    <div className="mt-8">
                        <Button onClick={submitQuote}>Submit</Button>
                        <SuccessDialog open={success}/>
                    </div>
                    <div className="mb-64">

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubmitQuote;
