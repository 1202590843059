import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ComplexNavbar } from "../components/Navbar";
import { Order } from "adriftrfq-types";
import { Card, Dialog, Tooltip, Typography } from "@material-tailwind/react";
import ViewFile from "../components/ViewFiles";
import Loading from "../components/Loading";
import ViewQuote from "../components/quote/ViewQuotes";
import Login from "../components/Login";

function ViewOrder() {
    /*const [order, setOrder] = useState<Order>({
        id: 'uuid',
        name: "Test Quote Request",
        items: [
            { sku: "[TEST] SKU A", product: "Flat Bar 40 x 10mm – 6MTR", quantity: "[TEST] QTY 4", note: "[TEST] Note A", files: ["https://hbr.org/resources/images/article_assets/2022/04/01-BI_WEB3_STACKPOLE_HERO.jpg"] },
            { sku: "[TEST] SKU B", product: "RHS 75 x 25 x 2mm – 8MTR or 3mm", quantity: "[TEST] QTY 8", files: ["https://hbr.org/resources/images/article_assets/2022/04/01-BI_WEB3_STACKPOLE_HERO.jpg"] },
            { sku: "[TEST] SKU C", product: "Plate 1500 x 3050 x 2mm Mild Steel", quantity: "[TEST] QTY 5", files: ["https://hbr.org/resources/images/article_assets/2022/04/01-BI_WEB3_STACKPOLE_HERO.jpg"] },
            { sku: "[TEST] SKU D", product: "Plate 2400 x 9600 x 6mm Dual Grade", quantity: "[TEST] QTY 4", note: "[TEST] Note D", files: ["https://hbr.org/resources/images/article_assets/2022/04/01-BI_WEB3_STACKPOLE_HERO.jpg"] },
            { sku: "[TEST] SKU E", product: "Plate 2400 x 9600 x 12mm 350 grade", quantity: "[TEST] QTY 2", files: ["https://hbr.org/resources/images/article_assets/2022/04/01-BI_WEB3_STACKPOLE_HERO.jpg"] }

        ],
        note: "Cool beans",
        suppliers: [
            { id: "TestSupplierID-A", name: "[TEST] Supplier A" },
            { id: "TestSupplierID-B", name: "[TEST] Supplier B" }
        ]
    });*/
    const [order, setOrder] = useState<Order>();

    const [orderId, setOrderID] = useState<string>("");
    const [orderName, setOrderName] = useState<string>("");
    const [searchParams] = useSearchParams();

    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string | null>(null);

    const [showTooltip, setShowTooltip] = useState<boolean[]>([]);

    useEffect(() => {
        const order = searchParams.get('order');
        if (order) {
            setOrderID(order);
        }
    }, [searchParams])

    useEffect(() => {
        if (order) {
            if (order.name && order.name !== order.id)
                setOrderName(order.name);

            setShowTooltip(Array(order.suppliers.length).fill(false))
        }
    }, [order])

    useEffect(() => {
        const getToken = async () => {
            try {
                const t = await getAccessTokenSilently();
                setToken(t);
                
            } catch (err) {
                console.log(err);
            }
        };
        getToken();
    }, [getAccessTokenSilently]);

    const baseurl: string = "https://api.adriftrfq.com";

    useEffect(() => {
        if (token) {
            const getOrder = async () => {
                try {
                  const response = await axios.get(`${baseurl}/orders/get`,
                    {
                      params: {
                        orderID: orderId
                      },
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  
                  console.log(response);
                  setOrder(response.data.order);

                } catch (error) {
                  console.log(error);
                }
              }
          
              getOrder();
        }
    }, [token, orderId])

    if (!order) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            {(isAuthenticated && user) ? (
                <div>
                <div className='flex justify-center xl:pt-4'>
                    <ComplexNavbar profile={user.picture} />
                </div>
                <div className="mt-24 px-4 flex flex-col w-full items-center justify-center">
                    <div className="max-w-screen-xl w-full">
                        <div className="font-medium text-[24px]">Viewing Quote Request<span className="font-normal text-blue-gray-700">{orderName ? `: ${orderName}` : ''}</span></div>
                        <div className="text-[14px] font-medium">Request ID: <span className="font-normal text-blue-gray-700">{orderId}</span></div>
    
                        <Card className="h-full w-full overflow-scroll mt-8">
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        <th key={"sku"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                SKU
                                            </Typography>
                                        </th>
                                        <th key={"product"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                Product
                                            </Typography>
                                        </th>
                                        <th key={"quantity"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                Quanity
                                            </Typography>
                                        </th>
                                        <th key={"files"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                View Files
                                            </Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {order.items.map((item, index) => {
                                    const isLast = index === order.items.length - 1;
                                    const classes = isLast ? "p-4 py-2" : "p-4 py-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.sku}
                                                </Typography>
                                                {item?.note && (
                                                        <div className="mt-4">
                                                            <div className="relative max-w-72 min-w-[200px]">
                                                                <div className=" h-full min-h-[60px] bg-blue-gray-50 text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y border-0 resize-none transition-all placeholder-shown:border  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 !resize-none" placeholder=" ">
                                                                    {item.note}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </td>
                                            <td className={classes}>   
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.product}
                                                </Typography>
                                            </td>
                                            <td className={classes}>   
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {item.quantity}
                                                </Typography>
                                            </td>
                                            <td className={classes}> 
                                                <ViewFile urls={item.files}/>
                                            </td>                                               
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </Card>
                        <div className="mt-8 font-medium text-[24px]">Note</div>
                        <div className="text-blue-gray-700 font-sans font-normal text-sm mt-0.5">This note will be displayed alongside your quote request.</div>
                        <div className="mt-2 peer w-full h-full min-h-[100px] bg-blue-gray-50 text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y border-0 resize-none transition-all placeholder-shown:border  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 !resize-none" placeholder=" ">
                            {order.note}
                        </div>
                        <Card className="h-full w-full overflow-scroll mt-8">
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        <th key={"sku"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                Supplier
                                            </Typography>
                                        </th>
                                        <th key={"product"} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                Supplier Submission Link (Click To Copy | Double Click To Open)
                                            </Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {order.suppliers.map((supplier, index) => {
                                    const isLast = index === order.items.length - 1;
                                    const classes = isLast ? "p-4 py-2" : "p-4 py-2 border-b border-blue-gray-50";
    
                                    const generateLink = (supplierId: string) => {
                                        return encodeURI(`https://app.adriftrfq.com/submitQuote?order=${orderId}&s=${supplierId}&supplier=${supplier.name}`);
                                    }
    
                                    const copyLink = (supplierId: string, index: number) => {
                                        navigator.clipboard.writeText(generateLink(supplierId));
                                        const a = [...showTooltip];
                                        a[index] = true;
                                        setShowTooltip(a)
    
                                        setTimeout(() => {
                                            const b = [...showTooltip];
                                            b[index] = false;
                                            setShowTooltip(b)
                                        }, 2000)
                                    }

                                    const handleClick = (supplierId: string, index: number, event: React.MouseEvent) => {
                                        if (event.detail === 1) {
                                            // Single click
                                            copyLink(supplierId, index);
                                        } else if (event.detail === 2) {
                                            // Double click
                                            window.open(generateLink(supplierId), '_blank');
                                        }
                                    }
    
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {supplier.name}
                                                </Typography>
                                            </td>
                                            <td className={classes}>  
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal cursor-pointer hover:opacity-80"
                                                onClick={(event: React.MouseEvent) => handleClick(supplier.id, index, event)}
                                            >
                                                <Tooltip content="Copied" className={showTooltip[index] ? '' : 'hidden'}>
                                                    {generateLink(supplier.id)}
                                                </Tooltip>
                                            </Typography> 
                                                
                                            </td>                                             
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </Card>
                        <div className="mt-16">
                            <ViewQuote orderId={orderId} token={token} order={order}/>
                        </div>
                        <div className="mb-64"/>
                    </div>
                </div>
            </div>
            ) : <Login/>}
        </>
    )
}

export default ViewOrder;