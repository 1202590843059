import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useState } from "react";

type ViewFilesProps = {
    urls: string[];
}

const ViewFile: React.FC<ViewFilesProps> = (props) => {
    const urls = props.urls;

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => setOpen(!open);

    return (
        <>
            <Button onClick={handleOpen} variant="text">
                View Files
            </Button>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Files</DialogHeader>
                <DialogBody className="flex flex-col gap-2">
                    {urls.map(url => {
                        return (
                            <a href={url} target="_blank" rel="noopener noreferer">
                                {getFileNameFromUrl(url)}
                            </a>
                        )
                    })}
                </DialogBody>
                <DialogFooter>
                <Button variant="gradient" onClick={handleOpen}>
                    <span>Close</span>
                </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default ViewFile;

function getFileNameFromUrl(url: string): string {
    // Create a URL object from the input string
    const urlObj = new URL(url);
  
    // Get the pathname part of the URL (everything after the domain)
    const pathname = urlObj.pathname;
  
    // Split the pathname by '/' and get the last part which is the file name
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
  
    // Decode the URL-encoded file name
    let decodedFileName = decodeURIComponent(fileName);
  
    // Use a regular expression to remove all patterns of 'number_'
    decodedFileName = decodedFileName.replace(/\d+_/g, '');
  
    return decodedFileName;
  }