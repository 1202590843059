import React, { useRef, forwardRef, useImperativeHandle } from "react";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

export interface ConfettiRef {
  shoot: () => void;
}

const Confetti = forwardRef<ConfettiRef>((props, ref) => {
  const fireworkController = useRef<any>();
  const realisticController = useRef<any>();

  // @ts-ignore
  const onInitHandler = ({ conductor }) => {
    fireworkController.current = conductor;
  };

  const shootFirework = () => {
    // @ts-ignore
    fireworkController.current?.shoot();
  };

  useImperativeHandle(ref, () => ({
    shoot: () => {
      let x = 0;

      let timer = setInterval(() => {
        if (x === 5) clearInterval(timer);
        shootFirework();
        x++;
      }, 500);
    },
  }));

  const decorateOptions = (defaultOptions: any) => {
    return {
      ...defaultOptions,
      //colors: ["#00000"],
    };
  };

  return (
    <>
      <Fireworks onInit={onInitHandler} decorateOptions={decorateOptions}/>
    </>
  );
});

export default Confetti;
