import React, { useState, ChangeEvent, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid"; // Import the uuid library
import { PencilIcon, TrashIcon, PlusIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  Typography,
  Input,
} from "@material-tailwind/react";
import { Supplier } from "adriftrfq-types";


type SupplierProps = {
  token: string | null;
}

const SuppliersDialog: React.FC<SupplierProps> = ({ token }) => {
  const [open, setOpen] = useState(false);
  const [tableRows, setTableRows] = useState<Supplier[]>([{id: "UUID", name: "Test Supplier", isEditing: false}]);

  useEffect(() => {
    if (token && token !== "") {
      const getSuppliers = async () => {
        try {
          const response = await axios.get(`${baseurl}/suppliers/list`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response.data);
          setTableRows(response.data.suppliers)
  
        } catch (error) {
          console.log(error);
        }
      }
      getSuppliers();
    }
  }, [token]);

  const handleOpen = () => setOpen(!open);

  const handleAddRow = () => {
    let newId: string = uuidv4(); // Generate a new UUID

    setTableRows([...tableRows, { id: newId, name: "", isEditing: true }]);
  };

  const handleNameChange = (index: number, newName: string) => {
    const updatedRows = [...tableRows];
    updatedRows[index].name = newName;
    setTableRows(updatedRows);
  };

  const handleEdit = (index: number) => {
    const updatedRows = [...tableRows];
    updatedRows[index].isEditing = true;
    setTableRows(updatedRows);
  };

  const handleConfirmEdit = (index: number) => {
    const updatedRows = [...tableRows];
    updatedRows[index].isEditing = false;
    setTableRows(updatedRows);
  };

  const handleDelete = (index: number) => {
    const updatedRows = tableRows.filter((_, i) => i !== index);
    setTableRows(updatedRows);
  };

  const baseurl: string = "https://api.adriftrfq.com"

  const handleConfirm = async () => {
    try {
      const response = await axios.post(`${baseurl}/suppliers/update`,
        {
          suppliers: tableRows,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.message);

    } catch (error) {
      console.log(error);
    }

    handleOpen();
  };

  return (
    <>
      <Button variant="outlined" className="font-normal text-[16px] cursor-pointer px-4" onClick={handleOpen}>
        Edit Suppliers
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Edit Suppliers</DialogHeader>
        <DialogBody>
          <SuppliersTable
            tableRows={tableRows}
            handleAddRow={handleAddRow}
            handleNameChange={handleNameChange}
            handleEdit={handleEdit}
            handleConfirmEdit={handleConfirmEdit}
            handleDelete={handleDelete}
          />
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" onClick={handleConfirm} className="mr-1">
            <span>Confirm</span>
          </Button>
          <Button variant="outlined" onClick={handleOpen}>
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

const TABLE_HEAD = ["Name", "Actions"]; // Removed "ID" from the table head

interface SuppliersTableProps {
  tableRows: Supplier[];
  handleAddRow: () => void;
  handleNameChange: (index: number, newName: string) => void;
  handleEdit: (index: number) => void;
  handleConfirmEdit: (index: number) => void;
  handleDelete: (index: number) => void;
}

function SuppliersTable({
  tableRows,
  handleAddRow,
  handleNameChange,
  handleEdit,
  handleConfirmEdit,
  handleDelete,
}: SuppliersTableProps) {
  return (
    <div>
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows.map(({ id, name, isEditing }, index) => {
              const isLast = index === tableRows.length - 1;
              const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={id}>
                  <td className={classes}>
                    {isEditing ? (
                      <Input
                        label="Supplier Name"
                        type="text"
                        value={name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleNameChange(index, e.target.value)}
                        className="w-full p-2 border rounded"
                      />
                    ) : (
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {name}
                      </Typography>
                    )}
                  </td>
                  <td className={classes}>
                    {isEditing ? (
                      <CheckCircleIcon
                        className="h-5 w-5 text-blue-gray-500 cursor-pointer"
                        onClick={() => handleConfirmEdit(index)}
                      />
                    ) : (
                      <div className="flex flex-row gap-2">
                        <PencilIcon
                          className="h-5 w-5 text-blue-gray-500 cursor-pointer inline"
                          onClick={() => handleEdit(index)}
                        />
                        <TrashIcon
                          className="h-5 w-5 text-blue-gray-500 cursor-pointer inline ml-2"
                          onClick={() => handleDelete(index)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
      <Button variant="text" onClick={handleAddRow} className="flex flex-row items-center gap-2 mt-2">
        <PlusIcon className="h-5 w-5 text-black" />
        Click to Add
      </Button>
    </div>
  );
}

export default SuppliersDialog;
